/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          /* Fancybox */
          $('[data-fancybox]').fancybox({
              // Options will go here
              buttons : [
                  'share',
                  'close'
              ],
          });
          /* END Fancybox */

          // Hide Header on on scroll down
            var didScroll;
            var lastScrollTop = 0;
            var delta = 2;
            var navbarHeight = $('.fixed-mobile').outerHeight();

            $(window).scroll(function(event) {
                didScroll = true;
            });

            function hasScrolled() {
                var st = $(this).scrollTop();

                // Make sure they scroll more than delta
                if (Math.abs(lastScrollTop - st) <= delta) {
                    return;
                }

                // If they scrolled down and are past the navbar, add class .nav-up.
                // This is necessary so you never see what is "behind" the navbar.
                if (st > lastScrollTop && st > navbarHeight) {
                    // Scroll Down
                    $('.fixed-mobile').addClass('menu-down');
                    if ($('.banner').css('display') === 'none') {
                        $('.page-header').addClass('mobile-active');
                    }

                } else if (st < 100) {
                    // Scroll to top
                    $('.fixed-mobile').removeClass('menu-down');
                    $('.page-header').removeClass('mobile-active');
                } else {
                    // Scroll Up
                    if (st > 100) {
                        $('.fixed-mobile').addClass('menu-down');
                    }
                }
                lastScrollTop = st;
            }

            setInterval(function() {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 250);
          // End Hide Header on on scroll down

          // Open-close Filter-menu
          $(".filter").click(function() {
              $(".filter-menu").addClass("active");
          });
          $(".close-btn").click(function() {
              $(".filter-menu").removeClass("active");
          });
          $(document).mouseup(function(e) {
              var container = $(".filter-menu");
              // if the target of the click isn't the container nor a descendant of the container
              if (!container.is(e.target) && container.has(e.target).length === 0) {
                  container.removeClass("active");
              }
          });
          // END Open-close Filter-menu

          // Open-close Main-menu
          $(".header-menu").click(function() {
              $(".main-menu").addClass("active");
              $(".window-opacity").addClass("top-active active");
          });
          $(".close-btn").click(function() {
              $(".main-menu").removeClass("active");
              $(".window-opacity").removeClass("active");
              setTimeout(function(){
                  $(".window-opacity").removeClass("top-active");
              }, 200);
          });
          $(document).mouseup(function(e) {
              var container = $(".main-menu");
              // if the target of the click isn't the container nor a descendant of the container
              if (!container.is(e.target) && container.has(e.target).length === 0) {
                  container.removeClass("active");
                  $(".window-opacity").removeClass("active");
                  if ($(".window-opacity").hasClass("top-active")) {
                      setTimeout(function(){
                          $(".window-opacity").removeClass("top-active");
                      }, 500);
                  }
              }
          });
          // END Open-close Main-menu

          // Parallax scrolling effect
          (function(){
              var parallax = document.querySelectorAll(".top-banner, .page-banner"),
                  speed = 0.5;
              window.onscroll = function(){
                  [].slice.call(parallax).forEach(function(el,i){

                      var windowYOffset = window.pageYOffset,
                          elBackgrounPos = "50% calc(50% - " + (windowYOffset * speed) + "px)";
                      el.style.backgroundPosition = elBackgrounPos;
                  });
              };
          })();
          // END Parallax scrolling effect

          // Accordion
          var acc = document.getElementsByClassName("accordion");
          var i;

          for (i = 0; i < acc.length; i++) {
              acc[i].onclick = function(){
                  this.classList.toggle("active");
                  var panel = this.nextElementSibling;
                  if (panel.style.display === "block") {
                      panel.style.display = "none";
                  } else {
                      panel.style.display = "block";
                  }
              };
          }

          for (i = 0; i < acc.length; i++) {
              acc[i].onclick = function() {
                  this.classList.toggle("active");
                  var panel = this.nextElementSibling;
                  if (panel.style.maxHeight){
                      panel.style.maxHeight = null;
                  } else {
                      panel.style.maxHeight = panel.scrollHeight + "px";
                  }
              };
          }
          // END Accordion

          // Midnight.js
          // Change this to the correct selector.
          $('nav.fixed').midnight();
          //END Midnight.js

          // Inject SVGs directly into DOM
          $('.js-ghost-svg').each(function () {
              var $el = $(this);
              $.ajax({
                  method: 'GET',
                  url: $el.data('src'),
                  dataType: 'text',
              }).done(function (data) {
                  $el.append(data);
              });
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

          // Home-page animation - Detect scroll up/down - Firefox
           /* $('body').on('DOMMouseScroll', function(e){
                if(e.originalEvent.detail < 0) {
                    if ($(".screen-3").hasClass("active")) {
                        $(".screen-3").removeClass("active");
                    } else if ($(".screen-3").css("height") < "479px") {
                        $(".screen-2").removeClass("active");
                    }
                }
                else {
                    if ($(".screen-2").css("height") > "479px") {
                        $(".screen-3").addClass("active");
                    } else {
                        $(".screen-2").addClass("active");
                    }
                }
            });

          // Home-page animation on click
          $(".les").click(function() {
              $(".screen-2").addClass("active");
          });
          $(".kakovost").click(function() {
              $(".screen-3").addClass("active");
          });

          // Home-page animation - Detect scroll up/down - Crome, Ie
          $(window).bind('mousewheel', function(event) {
            if (event.originalEvent.wheelDelta >= 0) {
                if ($(".screen-3").hasClass("active")) {
                    $(".screen-3").removeClass("active");
                } else if ($(".screen-3").css("height") < "479px") {
                    $(".screen-2").removeClass("active");
                }
            }
            else {
                if ($(".screen-2").css("height") > "479px") {
                    $(".screen-3").addClass("active");
                } else {
                    $(".screen-2").addClass("active");
                }
            }
        }); */
          // END Home-page animation

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
          // New home-page animation
          $('.page-template-template-home').bind('DOMMouseScroll', function(e){
              $(".num-one, .num-two, .num-three").addClass('avoid-clicks');
              setTimeout(function(){
                   $(".num-one, .num-two, .num-three").removeClass('avoid-clicks');
               }, 1600);

               if ($(".screen-1").hasClass('a-ready')) {
                   $(".screen-2").addClass('active');
                   $(".num-one").removeClass('active');
                   if ($(".screen-1").hasClass('top-z-index')) {
                       setTimeout(function(){
                           $(".num-two").addClass('active');
                       }, 700);
                   }
                   $(".screen-1").removeClass('top-z-index');
                   setTimeout(function(){
                       $(".screen-2").addClass("home-screen-opened");
                       $(".screen-1").removeClass('active a-ready');
                   }, 1500);

               } else if ($(".screen-2").hasClass("home-screen-opened")) {
                   $(".screen-3").addClass('active');
                   $(".num-two").removeClass('active');
                   if ($(".screen-2").hasClass('home-screen-opened')) {
                       setTimeout(function(){
                           $(".num-three").addClass('active');
                       }, 700);
                   }
                   $(".screen-2").removeClass('home-screen-opened top-z-index');
                   setTimeout(function(){
                       $(".screen-3").addClass("home-screen-opened");
                       $(".screen-2").removeClass('active');

                   }, 1500);
               } else if ($(".screen-3").hasClass("home-screen-opened")) {
                   $(".screen-1").addClass('active top-z-index');
                   $(".num-three").removeClass('active');
                   $(".screen-3").removeClass('home-screen-opened');
                   setTimeout(function(){
                       $(".num-one").addClass('active');
                   }, 700);
                   setTimeout(function(){
                       $(".screen-3").removeClass('active');
                       setTimeout(function(){
                           $(".screen-1").addClass('a-ready');
                       }, 1000);
                   }, 1000);

               }

              //prevent page fom scrolling
              return false;
          });

          //IE, Opera, Safari
          $('.page-template-template-home').bind('mousewheel', function(e){
              $(".num-one, .num-two, .num-three").addClass('avoid-clicks');
              setTimeout(function(){
                   $(".num-one, .num-two, .num-three").removeClass('avoid-clicks');
               }, 1600);

                if ($(".screen-1").hasClass('a-ready')) {
                   $(".screen-2").addClass('active');
                   $(".num-one").removeClass('active');
                   if ($(".screen-1").hasClass('top-z-index')) {
                       setTimeout(function(){
                           $(".num-two").addClass('active');
                       }, 700);
                   }
                   $(".screen-1").removeClass('top-z-index');
                   setTimeout(function(){
                       $(".screen-2").addClass("home-screen-opened");
                       $(".screen-1").removeClass('active a-ready');
                   }, 1500);

               } else if ($(".screen-2").hasClass("home-screen-opened")) {
                   $(".screen-3").addClass('active');
                   $(".num-two").removeClass('active');
                   if ($(".screen-2").hasClass('home-screen-opened')) {
                       setTimeout(function(){
                           $(".num-three").addClass('active');
                       }, 700);
                   }
                   $(".screen-2").removeClass('home-screen-opened top-z-index');
                   setTimeout(function(){
                       $(".screen-3").addClass("home-screen-opened");
                       $(".screen-2").removeClass('active');

                   }, 1500);
               } else if ($(".screen-3").hasClass("home-screen-opened")) {
                   $(".screen-1").addClass('active top-z-index');
                   $(".num-three").removeClass('active');
                   $(".screen-3").removeClass('home-screen-opened');
                   setTimeout(function(){
                       $(".num-one").addClass('active');
                   }, 700);
                   setTimeout(function(){
                       $(".screen-3").removeClass('active');
                       setTimeout(function(){
                           $(".screen-1").addClass('a-ready');
                       }, 1000);
                   }, 1000);

               }

             //prevent page fom scrolling
             return false;
          });

          // Homepage animation on click
          $(".num-one").click(function() {
              $(".screen-1").addClass('active top-z-index');
              $(".num-three").removeClass('active');
              $(".num-two").removeClass('active');
              $(".screen-3").removeClass('home-screen-opened');
              $(".screen-2").removeClass('home-screen-opened top-z-index');
              $(".screen-1").addClass('a-ready');
               setTimeout(function(){
                   $(".num-one").addClass('active');
               }, 700);
               setTimeout(function(){
                   $(".screen-3").removeClass('active');
                   $(".screen-2").removeClass('active');
               }, 1000);
          });
          $(".num-two").click(function() {
              $(".screen-2").addClass('active top-z-index');
              $(".num-three").removeClass('active');
              $(".num-one").removeClass('active');
              $(".screen-3").removeClass('home-screen-opened');
              $(".screen-2").addClass('home-screen-opened');
              $(".screen-1").removeClass('a-ready top-z-index');
               setTimeout(function(){
                   $(".num-two").addClass('active');
               }, 700);
               setTimeout(function(){
                   $(".screen-1").removeClass('active');
                   $(".screen-3").removeClass('active');
               }, 1000);
          });
          $(".num-three").click(function() {
              $(".screen-3").addClass('active');
              $(".num-two").removeClass('active');
              $(".num-one").removeClass('active');
              $(".screen-2").removeClass('home-screen-opened top-z-index');
              $(".screen-3").addClass('home-screen-opened');
              $(".screen-1").removeClass('a-ready top-z-index');
               setTimeout(function(){
                   $(".num-three").addClass('active');
               }, 700);
               setTimeout(function(){
                   $(".screen-1").removeClass('active');
                   $(".screen-2").removeClass('active');
               }, 1000);
          });
          $(".page-numbers").click(function() {
              $(".num-one, .num-two, .num-three").addClass('avoid-clicks');
              setTimeout(function(){
                   $(".num-one, .num-two, .num-three").removeClass('avoid-clicks');
               }, 1600);
          });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'post_type_archive_projekti': {
      init: function () {
        var searchQuery = (function () {
          var query = [];
          return {
            set: function (queryvar, value) {
              query =
                query.
                  filter(function (q) { return q.queryvar !== queryvar; }).
                  concat([{ queryvar: queryvar, value: value }]);
            },
            run: function () {
              var querystring =
                [window.location.origin, '/projekti/?'].
                  concat(query.
                    map(function (q) { return q.queryvar + '=' + q.value; }).
                    join('&')
                  ).join('');
              window.location.assign(querystring);
            },
            init: function () {
              query = window.location.search.substr(1).split('&').
                map(function (qpair) {
                  var pair = qpair.split('=');
                  if (['locality', 'area', 'private', 'public'].indexOf(pair[0]) > -1) {
                    return { queryvar: pair[0], value: pair[1] };
                  }
                }).
                filter(function (val) { return typeof val === 'object'; });
            }
          };
        })();

        var handleSelect = function (e) {
          e.preventDefault();
          $(this).parents('.dropdown').find('.dropdown-toggle').text(this.innerText);
          for (var prop in this.dataset) {
            searchQuery.set(prop, this.dataset[prop]);
          }
        };

        var handleToggle = function () {
          for (var prop in this.dataset) {
            searchQuery.set(prop, this.checked ? 1 : -1);
          }
        };

        searchQuery.init();
        $('.js-filter-select').on('click', handleSelect);
        $('.js-filter-toggle').on('change', handleToggle);
        $('.js-filter-apply').on('click', searchQuery.run);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


$(window).on('load', function () {
    console.log('images loaded');
    // Masonry grid
    var elem = document.querySelector('.grid');
    if (elem) {
        new Masonry(elem, {
            // options
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        });
    }
    // END Masonry grid
});